import React from 'react';
import {Router} from '@reach/router';

import {routes} from '../model/navigation/routes';
import lang from '../lang/pages/deine-apotheken.lang';
import Layout from '../ui/layout/Layout';
import {Section, Container} from '../ui/layout/Grid';
import Headline from '../ui/components/Headline';
import Paragraph from '../ui/components/Paragraph';
import PharmacyUserDetailPage from '../ui/domains/sales-agent-user-interactions/PharmacyUserDetailPage';
import ConnectedPharmacyTable from '../ui/domains/sales-agent-user-interactions/PharmacyTable';

const DeineApothekenPage = () => (
    <Layout>
        <Section>
            <Container width="narrow" className="text-center">
                <Headline type="h1">{lang.headline}</Headline>
                <Paragraph>{lang.paragraph1}</Paragraph>
            </Container>
        </Section>
        <ConnectedPharmacyTable theme="blue" />
    </Layout>
);

export const DeineApotheken = () => {
    return (
        // NOTE: this is client-only routing, see gatsby-node.js => exports.onCreatePage
        <Router basepath={routes.apothekentabelle}>
            <PharmacyUserDetailPage path="/user/:userId" />
            <DeineApothekenPage path="/" />
        </Router>
    );
};

export default DeineApotheken;
