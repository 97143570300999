import styled from 'styled-components';

import {color} from '../../../styles/variables';
import {lg} from '../../../styles/media';

import Button from '../Button';

export const PaginationButton = styled(Button)`
    width: 2.5rem;
    height: 2.5rem;
    background-color: ${(props) => (props.active ? color.wine : color.white)};
    color: ${(props) => (props.active ? color.white : color.wine)};
    border: 1px solid ${color.wine};
    margin: 0.3rem;
    padding: 0;
`;

export const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`;
export const TotalWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    ${lg`
        justify-content: flex-end;
        align-items: flex-start;
	`};
    height: 100%;
`;
