import {createSelector} from 'reselect';

export const selectDomain = (state) => state.pharmacyUsers;

export const selectUsers = createSelector(
    selectDomain,
    (domain) => domain.users
);

export const selectSearch = createSelector(
    selectDomain,
    (domain) => domain.search
);

export const selectPaginationData = createSelector(
    selectDomain,
    (domain) => domain.pagination
);

export const selectCurrentPage = createSelector(
    selectPaginationData,
    (pagination) => pagination.currentPage
);

export const selectTotalPages = createSelector(
    selectPaginationData,
    (pagination) => pagination.totalPages
);
export const selectTotalUsers = createSelector(
    selectPaginationData,
    (pagination) => pagination.totalUsers
);
export const selectToPage = createSelector(
    selectPaginationData,
    (pagination) => pagination.toPage
);
export const selectPerPage = createSelector(
    selectPaginationData,
    (pagination) => pagination.perPage
);
