import React from 'react';

export default {
    headline: (
        <i>
            Deine <strong>Apotheken</strong>
        </i>
    ),
    paragraph1:
        'Du suchst einen User einer Apotheke aus Deinem Gebiet? In Deiner Apothekentabelle findest Du alle Informationen.',
    searchForm: {
        name: 'pharmacySearch',
        label: 'Apotheken-Suche',
        placeholder: 'PLZ, Ort oder Apotheken-Namen eingeben',
        submitButton: 'Apotheke suchen',
        clearButton: 'Suche zurücksetzen',
    },
    tableHeadings: [
        <>Apo&shy;theke</>,
        'PLZ',
        'Ort',
        <>Vor&shy;name</>,
        <>Nach&shy;name</>,
        'Beruf',
        <>News&shy;letter</>,
        <>Akademie&shy;teilnahme</>,
        <>Anmelde&shy;datum</>,
        <>Trai&shy;nings</>,
    ],
    detailPage: {
        tableHeadings: [
            'Datum',
            'Grund',
            'Punkte',
            'Punkte vor Vergabe',
            'Punkte nach Vergabe',
            'Status',
        ],
    },
};
