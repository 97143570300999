/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useForm} from 'react-hook-form';
import {useDispatch, useSelector} from 'react-redux';
import {navigate} from 'gatsby';

import {
    loadPharmacyUsers,
    selectCurrentPage,
    selectTotalPages,
    selectUsers,
    selectTotalUsers,
    selectToPage,
    selectPerPage,
    setSearch,
    selectSearch,
    clearSearch,
} from '../../../../model/pharmacy-users';
import {Section, Container, Row, Column} from '../../../layout/Grid';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';
import Form, {Input} from '../../../components/Form';
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import pharmacyTableDataRenderer from './pharmacyTableDataRenderer';
import lang from '../../../../lang/pages/deine-apotheken.lang';
import Pagination from '../../../components/Pagination';
import ResponsiveSwitch from '../../../layout/ResponsiveSwitch';
import {routes} from '../../../../model/navigation/routes';

const defaultValues = {
    pharmacySearch: '',
};

export const PharmacyTable = ({
    theme,
    tableData,
    totalPages,
    totalUsers,
    toPage,
    currentPage,
    perPage,
    onSubmit,
    search,
    setSearch,
    clearSearch,
}) => {
    const [sortColumn, setSortColumn] = useState([{0: 'desc'}]);
    const [localSearch, setLocalSearch] = useState(search);
    const [page, setPage] = useState(1);

    const {control, handleSubmit, reset, setValue} = useForm({
        defaultValues,
    });

    const submitForm = (data) => {
        setLocalSearch(data.pharmacySearch);
        setSearch(data.pharmacySearch);
        setPage(1);
    };

    const handleClearSearch = () => {
        setLocalSearch('');
        clearSearch();
        reset({pharmacySearch: ''});
        setPage(1);
    };

    useEffect(() => {
        setValue('pharmacySearch', localSearch);
        onSubmit(page, sortColumn, localSearch);
    }, [page, sortColumn, localSearch]);

    return (
        <Section theme={theme}>
            <Form theme={theme} onSubmit={handleSubmit(submitForm)}>
                <Container width="wide">
                    <Row style={{justifyContent: 'center'}}>
                        <ResponsiveSwitch>
                            {(match) => {
                                if (match.mdMax) {
                                    return (
                                        <>
                                            <Column xs={12}>
                                                <Input
                                                    name={lang.searchForm.name}
                                                    placeholder={
                                                        lang.searchForm
                                                            .placeholder
                                                    }
                                                    label={
                                                        lang.searchForm.label
                                                    }
                                                    theme={theme}
                                                    control={control}
                                                    errors={{}}
                                                />
                                            </Column>
                                            <Column xs={12} sm={4}>
                                                <Button
                                                    type="submit"
                                                    label={
                                                        lang.searchForm
                                                            .submitButton
                                                    }
                                                    className="ap-button ap-button--inline"
                                                />
                                            </Column>
                                            <Column xs={12} sm={4}>
                                                <Button
                                                    type="clear"
                                                    className="ap-button ap-button--inline"
                                                    onClick={handleClearSearch}
                                                    label={
                                                        lang.searchForm
                                                            .clearButton
                                                    }
                                                />
                                            </Column>
                                        </>
                                    );
                                } else {
                                    return (
                                        <>
                                            <Column
                                                md={12}
                                                lg={12}
                                                xl={12}
                                                xxl={6}
                                                style={{
                                                    paddingRight: 0,
                                                }}>
                                                <Input
                                                    name={lang.searchForm.name}
                                                    placeholder={
                                                        lang.searchForm
                                                            .placeholder
                                                    }
                                                    label={
                                                        lang.searchForm.label
                                                    }
                                                    theme={theme}
                                                    control={control}
                                                    errors={{}}
                                                />
                                            </Column>
                                            <Column
                                                md={4}
                                                lg={4}
                                                xl={3}
                                                xxl={2}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                    paddingRight: 0,
                                                }}>
                                                <Button
                                                    type="submit"
                                                    label={
                                                        lang.searchForm
                                                            .submitButton
                                                    }
                                                    className="ap-button fullWidth"
                                                />
                                            </Column>
                                            <Column
                                                md={4}
                                                lg={4}
                                                xl={3}
                                                xxl={2}
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-end',
                                                    paddingRight: 0,
                                                }}>
                                                <Button
                                                    type="clear"
                                                    className="ap-button ap-button--inline fullWidth"
                                                    onClick={handleClearSearch}
                                                    label={
                                                        lang.searchForm
                                                            .clearButton
                                                    }
                                                />
                                            </Column>
                                        </>
                                    );
                                }
                            }}
                        </ResponsiveSwitch>
                    </Row>
                </Container>
            </Form>
            <Container width="xtra-wide" noGutter>
                <Row style={{fontSize: '16px'}}>
                    <Column xs={12}>
                        <Table
                            theme={theme}
                            cols={lang.tableHeadings}
                            rows={tableData}
                            dataRenderer={pharmacyTableDataRenderer}
                            sortable
                            sort={sortColumn}
                            setSort={(col) => {
                                setSortColumn(col);
                            }}
                            onRowClick={(rowData) =>
                                // NOTE: this is a client-only route, see gatsby-node.js => exports.onCreatePage
                                navigate(
                                    `${routes.apothekentabelle}/user/${rowData.id}`
                                )
                            }
                        />
                    </Column>
                </Row>
            </Container>
            <Container width="xtra-wide">
                <Row>
                    <Column xs={12}>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            total={totalUsers}
                            toPage={toPage}
                            perPage={perPage}
                            handleButtonClick={(page) => setPage(page)}
                        />
                    </Column>
                </Row>
            </Container>
        </Section>
    );
};

PharmacyTable.propTypes = {
    theme: PropTypes.oneOf(sectionThemeNames),
    tableData: PropTypes.arrayOf(
        PropTypes.shape({
            pharmacy: PropTypes.string,
            zip: PropTypes.string,
            city: PropTypes.string,
            firstName: PropTypes.string,
            lastName: PropTypes.string,
            profession: PropTypes.string,
            newsletter: PropTypes.string,
            registrationDate: PropTypes.string,
            points: PropTypes.number,
        })
    ),
    currentPage: PropTypes.number,
    totalPages: PropTypes.number,
    totalUsers: PropTypes.number,
    toPage: PropTypes.number,
    perPage: PropTypes.number,
    onPageChange: PropTypes.func,
    onSubmit: PropTypes.func,
    search: PropTypes.string,
    setSearch: PropTypes.func,
    clearSearch: PropTypes.func,
};

PharmacyTable.defaultProps = {
    theme: 'default',
    tableData: [],
};

const ConnectedPharmacyTable = (props) => {
    const pharmacyTable = useSelector(selectUsers);
    const currentPage = useSelector(selectCurrentPage);
    const totalPages = useSelector(selectTotalPages);
    const totalUsers = useSelector(selectTotalUsers);
    const toPage = useSelector(selectToPage);
    const perPage = useSelector(selectPerPage);
    const search = useSelector(selectSearch);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadPharmacyUsers({search}));
    }, [dispatch]);

    const handleSubmit = (page, sortColumn, search) => {
        dispatch(loadPharmacyUsers({page, sortColumn, search}));
    };

    const handleSearchTermChange = (search) => {
        dispatch(setSearch(search));
    };

    const handleSearchTermClear = () => {
        dispatch(clearSearch());
    };

    return (
        <PharmacyTable
            {...props}
            onSubmit={handleSubmit}
            tableData={pharmacyTable}
            currentPage={currentPage}
            totalPages={totalPages}
            totalUsers={totalUsers}
            toPage={toPage}
            perPage={perPage}
            search={search}
            setSearch={handleSearchTermChange}
            clearSearch={handleSearchTermClear}
        />
    );
};

export default ConnectedPharmacyTable;
