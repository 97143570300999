import {createSelector} from 'reselect';

export const selectDomain = (state) => state.pharmacyUserDetails;

export const selectDetails = createSelector(
    selectDomain,
    (domain) => domain.details
);

export const selectPointsHistory = createSelector(
    selectDomain,
    (domain) => domain.pointsHistory
);

export const selectPagination = createSelector(
    selectDomain,
    (domain) => domain.pagination
);
