import React from 'react';
import PropTypes from 'prop-types';

import Headline from '../../../../components/Headline';
import {Item} from '../../../../components/Table/styles';
import {dateFormatPrecise} from '../../../../../utils/date-utils';

// Spaltennamen: ['Datum', ..., 'Status'] (siehe detailPage.tableHeadings in deine-apotheken.lang.js)

const pharmacyUserPointsHistoryDataRenderer = (
    type,
    colIndex,
    headings,
    rowIndex,
    data
) => {
    if (type === 'heading') {
        return (
            <Headline
                key={`${rowIndex}.${colIndex}`}
                type="h6"
                fontWeight="bold"
                flexGrow={colIndex === 1 ? 4 : 1}
                hideTopMargin
                hideBottomMargin>
                {headings[colIndex]}
            </Headline>
        );
    } else if (type === 'data') {
        switch (colIndex) {
            case 0:
                return (
                    <Item key={`${rowIndex}.${colIndex}`}>
                        {dateFormatPrecise(data[rowIndex].date)}
                    </Item>
                );
            case 1:
                return (
                    <Item key={`${rowIndex}.${colIndex}`} flexGrow={4}>
                        {data[rowIndex].reason}
                    </Item>
                );
            case 2:
                return (
                    <Item key={`${rowIndex}.${colIndex}`}>
                        {data[rowIndex].points}
                    </Item>
                );
            case 3:
                return (
                    <Item key={`${rowIndex}.${colIndex}`}>
                        {data[rowIndex].pointsBefore}
                    </Item>
                );
            case 4:
                return (
                    <Item key={`${rowIndex}.${colIndex}`}>
                        {data[rowIndex].pointsAfter}
                    </Item>
                );
            case 5:
                return (
                    <Item key={`${rowIndex}.${colIndex}`}>
                        {data[rowIndex].status}
                    </Item>
                );
            default:
                return null;
        }
    }
};

pharmacyUserPointsHistoryDataRenderer.propTypes = {
    type: PropTypes.string,
    colIndex: PropTypes.number,
    headings: PropTypes.array,
    rowIndex: PropTypes.number,
    data: PropTypes.array,
};

export default pharmacyUserPointsHistoryDataRenderer;
