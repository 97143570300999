import React from 'react';
import PropTypes from 'prop-types';

import Headline from '../../../../components/Headline';
import {HeadingWrapper, Item} from '../../../../components/Table/styles';
import Icon from '../../../../components/Icon';
import {color} from '../../../../../styles/variables';
import {dateFormat} from '../../../../../utils/date-utils';

/* Spaltennamen: [
    'Apotheke',
    'PLZ',
    'Ort',
    'Vorname',
    'Nachname',
    'Beruf',
    'Newsletter',
    'Anmeldedatum',
    'Trainings',
], (siehe tableHeadings in deineApotheke.lang.js) */

const pharmacyTableDataRenderer = (
    type,
    colIndex,
    headings,
    rowIndex,
    data,
    onClick,
    sortOrder
) => {
    if (type === 'sortHeading') {
        return (
            <HeadingWrapper
                key={colIndex}
                flexGrow={colIndex === 0 ? 2 : 1}
                onClick={onClick}
                sort={sortOrder}>
                <Headline
                    type="h6"
                    fontWeight="bold"
                    hideTopMargin
                    hideBottomMargin>
                    {headings[colIndex]}
                </Headline>
                <Icon name="sortArrows" color={color.grey} size="1.25em" />
            </HeadingWrapper>
        );
    } else if (type === 'heading') {
        return (
            <Headline
                type="h6"
                fontWeight="bold"
                hideTopMargin
                hideBottomMargin>
                {headings[colIndex]}
            </Headline>
        );
    } else if (type === 'data') {
        let dataKeys = Object.keys(data[rowIndex]).filter(
            (key) => key !== 'id' // do not render id field because it has no associated column
        );
        if ([dataKeys[colIndex]][0] === 'createdAt') {
            return (
                <Item
                    key={`${rowIndex}.${colIndex}`}
                    flexGrow={colIndex === 0 ? 2 : 1}
                    bold={colIndex === 0 || colIndex === headings.length - 1}
                    red={colIndex === headings.length - 1}>
                    {dateFormat(data[rowIndex][dataKeys[colIndex]])}
                </Item>
            );
        }
        return (
            <Item
                key={`${rowIndex}.${colIndex}`}
                flexGrow={colIndex === 0 ? 2 : 1}
                bold={colIndex === 0 || colIndex === headings.length - 1}
                red={colIndex === headings.length - 1}>
                {data[rowIndex][dataKeys[colIndex]]}
            </Item>
        );
    }
};

pharmacyTableDataRenderer.propTypes = {
    type: PropTypes.string,
    colIndex: PropTypes.number,
    headings: PropTypes.array,
    rowIndex: PropTypes.number,
    data: PropTypes.array,
    onClick: PropTypes.func,
    sortOrder: PropTypes.string,
};

export default pharmacyTableDataRenderer;
