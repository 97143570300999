import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveSwitch from '../../layout/ResponsiveSwitch';
import {
    HeaderRow,
    DataRow,
    MobileWrapper,
    MobileSortingWrapper,
} from './styles';
import {sectionThemeNames} from '../../layout/Grid/Section.styles';

const Table = ({
    theme,
    cols,
    rows,
    dataRenderer,
    sortable,
    sort,
    setSort,
    onRowClick,
}) => {
    const numCols = cols.length;

    const handleSort = (col) => {
        switch (sort[col]) {
            case 'desc':
                return setSort({[col]: 'asc'});
            case 'asc':
                return setSort({[col]: 'desc'});
            default:
                return setSort({[col]: 'desc'});
        }
    };

    return (
        <ResponsiveSwitch>
            {(match) => {
                if (match.lg) {
                    return (
                        <>
                            <HeaderRow theme={theme}>
                                {[...Array(numCols).keys()].map((colIndex) =>
                                    sortable
                                        ? dataRenderer(
                                              'sortHeading',
                                              colIndex,
                                              cols,
                                              null,
                                              [],
                                              () => handleSort(colIndex),
                                              sort[colIndex]
                                          )
                                        : dataRenderer(
                                              'heading',
                                              colIndex,
                                              cols
                                          )
                                )}
                            </HeaderRow>
                            {rows.map((rowData, rowIndex) => (
                                <DataRow
                                    key={rowIndex}
                                    onClick={
                                        onRowClick
                                            ? () => onRowClick(rowData)
                                            : null
                                    }>
                                    {[
                                        ...Array(numCols).keys(),
                                    ].map((colIndex) =>
                                        dataRenderer(
                                            'data',
                                            colIndex,
                                            cols,
                                            rowIndex,
                                            rows
                                        )
                                    )}
                                </DataRow>
                            ))}
                        </>
                    );
                } else {
                    return (
                        <>
                            {sortable &&
                                [...Array(numCols).keys()].map((colIndex) => (
                                    <MobileSortingWrapper
                                        key={colIndex}
                                        onClick={() => handleSort(colIndex)}
                                        sort={sort[colIndex]}>
                                        {dataRenderer(
                                            'sortHeading',
                                            colIndex,
                                            cols
                                        )}
                                    </MobileSortingWrapper>
                                ))}
                            {rows.map((rowData, rowIndex) => (
                                <DataRow
                                    key={rowIndex}
                                    onClick={
                                        onRowClick
                                            ? () => onRowClick(rowData)
                                            : null
                                    }>
                                    {[...Array(numCols).keys()].map(
                                        (colIndex) => (
                                            <MobileWrapper
                                                key={`${rowIndex}.${colIndex}`}>
                                                {dataRenderer(
                                                    'heading',
                                                    colIndex,
                                                    cols
                                                )}
                                                {dataRenderer(
                                                    'data',
                                                    colIndex,
                                                    cols,
                                                    rowIndex,
                                                    rows
                                                )}
                                            </MobileWrapper>
                                        )
                                    )}
                                </DataRow>
                            ))}
                        </>
                    );
                }
            }}
        </ResponsiveSwitch>
    );
};

Table.propTypes = {
    theme: PropTypes.oneOf(sectionThemeNames),
    cols: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    dataRenderer: PropTypes.func.isRequired,
    sortable: PropTypes.bool,
    setSort: PropTypes.func,
    sort: PropTypes.any,
    onRowClick: PropTypes.func,
};

Table.defaultProps = {
    theme: 'default',
    setSort: () => {},
    sort: [],
};

export default Table;
