import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import Layout from '../../../layout/Layout';
import {Section, Container} from '../../../layout/Grid';
import Headline from '../../../components/Headline';
import Paragraph from '../../../components/Paragraph';
import Link from '../../../components/Link';
import PharmacyUserPointsHistory from '../PharmacyUserPointsHistory';
import {routes} from '../../../../model/navigation/routes';
import {
    loadDetails,
    selectDetails,
} from '../../../../model/pharmacy-user-details';

const PharmacyUserDetailPage = ({userId}) => {
    const dispatch = useDispatch();
    const details = useSelector(selectDetails);
    useEffect(() => {
        dispatch(loadDetails(userId));
    }, [dispatch, userId]);
    if (userId == null || details == null) {
        return null;
    }
    return (
        <Layout>
            <Section>
                <Container width="medium">
                    <Link
                        href={routes.apothekentabelle}
                        icon="arrowBack"
                        iconLeft>
                        zurück
                    </Link>
                </Container>
                <Container width="narrow" className="text-center">
                    <Headline type="h1">
                        {details.firstName} {details.lastName}
                        {details.userType ? `, ${details.userType}` : ''}
                    </Headline>
                    <Paragraph>
                        {details.pharmacy}
                        <br />
                        {details.zipCode} {details.city}
                    </Paragraph>
                </Container>
            </Section>
            <PharmacyUserPointsHistory theme="blue" userId={userId} />
        </Layout>
    );
};

PharmacyUserDetailPage.propTypes = {
    userId: PropTypes.string,
};

export default PharmacyUserDetailPage;
