import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import {
    loadPointsHistory,
    selectPointsHistory,
    selectPagination,
} from '../../../../model/pharmacy-user-details';

import {Section, Container, Row, Column} from '../../../layout/Grid';
import Pagination from '../../../components/Pagination';
import lang from '../../../../lang/pages/deine-apotheken.lang';
import Table from '../../../components/Table';
import pharmacyUserPointsHistoryDataRenderer from './pharmacyUserPointsHistoryDataRenderer';
import {sectionThemeNames} from '../../../layout/Grid/Section.styles';

export const PharmacyUserPointsHistory = ({
    userId,
    theme,
    tableData,
    totalPages,
    currentPage,
    onPageChange,
}) => {
    return (
        <Section theme={theme}>
            <Container width="xtra-wide" noGutter>
                <Row>
                    <Column xs={12}>
                        <Table
                            theme={theme}
                            cols={lang.detailPage.tableHeadings}
                            rows={tableData}
                            dataRenderer={pharmacyUserPointsHistoryDataRenderer}
                        />
                    </Column>
                </Row>
            </Container>
            <Container width="xtra-wide">
                <Row>
                    <Column xs={12}>
                        <Pagination
                            totalPages={totalPages}
                            currentPage={currentPage}
                            handleButtonClick={(page) => {
                                if (currentPage !== page) {
                                    onPageChange(page);
                                }
                            }}
                        />
                    </Column>
                </Row>
            </Container>
        </Section>
    );
};

PharmacyUserPointsHistory.propTypes = {
    userId: PropTypes.string,
    theme: PropTypes.oneOf(sectionThemeNames),
    tableData: PropTypes.arrayOf(
        PropTypes.shape({
            date: PropTypes.string,
            message: PropTypes.string,
            change: PropTypes.string,
        })
    ),
    totalPages: PropTypes.number,
    currentPage: PropTypes.number,
    onPageChange: PropTypes.func,
};

PharmacyUserPointsHistory.defaultProps = {
    theme: 'default',
    tableData: [],
};

const ConnectedPharmacyUserPointsHistory = (props) => {
    const {userId} = props;
    const pointsHistory = useSelector(selectPointsHistory);
    const {currentPage, totalPages} = useSelector(selectPagination);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(loadPointsHistory(userId));
    }, [dispatch, userId]);
    return (
        <PharmacyUserPointsHistory
            {...props}
            tableData={pointsHistory}
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={(page) => {
                dispatch(loadPointsHistory(userId, page));
            }}
        />
    );
};

ConnectedPharmacyUserPointsHistory.propTypes =
    PharmacyUserPointsHistory.propTypes;

export default ConnectedPharmacyUserPointsHistory;
