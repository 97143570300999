import styled, {css} from 'styled-components';

import {sm, lg} from '../../../styles/media';
import {color, border, font} from '../../../styles/variables';

const cellStyles = css`
    ${(props) => props.red && `color: ${color.red};`}
    ${(props) => props.bold && `font-weight: ${font.weight.bold};`}

    ${sm`
        flex-grow: ${(props) => (props.flexGrow ? props.flexGrow : 1)};
        flex-shrink: 1;
    `}
`;

export const MobileWrapper = styled.div`
    display: flex;
`;

export const Item = styled.span`
    ${cellStyles}
    word-break: break-word;
`;

export const Wrapper = styled.div`
    ${cellStyles}
    display: flex;
    text-align: center;

    &:last-child {
        text-align: center !important;
    }

    ${lg`
        justify-content: space-between;
    `}

    div {
        &:not(:last-child) {
            padding-right: 0.5rem;
        }

        &:hover {
            color: ${color.petrol};
            cursor: pointer;

            .ap-icon {
                svg {
                    fill: ${color.petrol};
                }
            }
        }
    }

    .ap-icon {
        margin: 0 auto;
    }

    img {
        transition: transform 0.3s ease-in-out;

        &:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    }
`;

export const DataRow = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    background-color: ${color.white};
    ${(props) =>
        props.onClick &&
        css`
            cursor: pointer;
            &:hover {
                background-color: ${color.lightPetrol};
            }
        `}

    &:not(:last-child) {
        border-bottom: ${border.lightBlue};
    }

    ${sm`
        padding: 20px;
    `}

    ${lg`
        flex-direction: row;
        align-items: center;
    `}

    h6,
    ${Item},
    ${Wrapper} {
        flex-basis: 120px;

        ${lg`
            &:not(:last-child) {
                padding-right: 0.5rem;
            }

            &:last-child {
                text-align: right;
            }
        `}
    }

    ${MobileWrapper} {
        padding: 10px 0;

        h6 {
            flex: 1 1 40%;
            padding-right: 10px;
        }

        ${Item}, ${Wrapper} {
            flex: 1 1 60%;
        }

        ${sm`
            h6, ${Item}, ${Wrapper} {
                flex: 1 1 50%;
            }

        `}
    }
`;

export const HeaderRow = styled(DataRow)`
    background-color: ${(props) =>
        props.theme === 'blue' ? color.lightBlue : color.white};
`;

export const HeadingWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    flex-basis: 120px;
    flex-grow: ${(props) => (props.flexGrow ? props.flexGrow : 1)};
    flex-shrink: 1;
    cursor: pointer;

    &:last-child {
        h6 {
            text-align: right;
        }
    }

    &:not(:last-child) {
        h6 {
            flex-grow: 0;
            flex-basis: unset;
        }

        .ap-icon {
            margin-right: 0.5rem;
        }
    }

    .ap-icon {
        svg {
            ${(props) =>
                props.sort === 'asc'
                    ? css`
                          path[data-name='path1'] {
                              fill: ${color.red}
                      `
                    : props.sort === 'desc'
                    ? css`
                          path[data-name='path2'] {
                              fill: ${color.red};
                          }
                      `
                    : css`
                          path {
                              fill: ${color.grey};
                          }
                      `}
        }
    }
`;

export const MobileSortingWrapper = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 1rem;
    cursor: pointer;

    .ap-icon {
        margin-left: 0.5rem;

        svg {
            ${(props) =>
                props.sort === 'asc'
                    ? css`
                          path[data-name='path1'] {
                              fill: ${color.red}
                      `
                    : props.sort === 'desc'
                    ? css`
                          path[data-name='path2'] {
                              fill: ${color.red};
                          }
                      `
                    : css`
                          path {
                              fill: ${color.grey};
                          }
                      `}
        }
    }
`;
