import React from 'react';
import PropTypes from 'prop-types';

import {Row, Column} from '../../../ui/layout/Grid';

import {PaginationButton, PaginationWrapper, TotalWrapper} from './styles';

const Pagination = ({
    totalPages,
    currentPage,
    handleButtonClick,
    total,
    toPage,
    perPage,
}) => {
    const mathFirstItemNumber = (currentPage - 1) * perPage + 1;
    return (
        <Row>
            <Column md={12} lg={8} offset={{lg: 2}}>
                <PaginationWrapper>
                    {Array(totalPages)
                        .fill()
                        .map((_, i) => (
                            <PaginationButton
                                secondary={true}
                                active={currentPage === i + 1}
                                onClick={() => handleButtonClick(i + 1)}
                                label={i + 1 + ''}
                                key={i + 1}
                            />
                        ))}
                </PaginationWrapper>
            </Column>
            {total != null && toPage != null && (
                <Column md={12} lg={2}>
                    <TotalWrapper>
                        {mathFirstItemNumber} - {toPage} von {total} Usern
                    </TotalWrapper>
                </Column>
            )}
        </Row>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number,
    handleButtonClick: PropTypes.func,
    totalPages: PropTypes.number,
    total: PropTypes.number,
    toPage: PropTypes.number,
    perPage: PropTypes.number,
};

export default Pagination;
